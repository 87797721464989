<template>
	<b-container>
		<ResourceList :has-search="false" :can-select-many="false" :has-buttons="false" :can-create="false" resource="supply return" resource-plural="supply returns" title="Pending Supply Returns" :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination" @paginate="onPaginate">
			<template #listItem="{ item }">
				<div class="d-flex flex-column flex-fill">
					<div class="d-flex flex-row flex-fill">
						<p class="h5 flex-fill mb-0 text-dark">{{ item.product.name }}</p>
						<p class="align-self-end mb-0 text-muted" v-if="item.supply_order_item_lot_id == null">Quantity: {{ item.quantity }}</p>
						<p class="align-self-end mb-0 text-muted" v-else>Length: {{ item.supply_order_item_lot.length }}m</p>
					</div>
					<div class="d-flex flex-row flex-fill mt-2">
						<b-button class="mr-1 mb-0" size="sm" variant="primary" @click="markAsReturned(item.id)"><b-icon icon="check" /> Mark Returned</b-button>
					</div>
				</div>
			</template>
		</ResourceList>
	</b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList"
import currentUser from "../../mixins/currentUser"
import { mapActions, mapGetters } from "vuex"
import FriendlyDate from "../../components/FriendlyDate"

export default {
	mixins: [currentUser],
	created() {
		this.fetchMany(this.$route.query)
	},
	components: { ResourceList, FriendlyDate },
	data: () => ({
		status: null
	}),
	computed: {
		...mapGetters("authentication", ["hasAnyPermission"]),
		...mapGetters("supply-returns", ["data", "isDeleting", "isLoading", "pagination"])
	},
	methods: {
		...mapActions("supply-returns", ["deleteMany", "fetchMany"]),
		async onPaginate(page) {
			if (this.$route.query.page !== page) {
				this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
				await this.fetchMany({ ...this.$route.query, page, status: this.status })
			}
		},
		async doPaginate() {
			await this.fetchMany({
				...this.$route.query,
				status: this.status
			})
		},
		markAsReturned(returnId) {
			window.axios.post(`/supply-returns/mark-as-returned/${returnId}`)
				.then(response => {
					this.fetchMany(this.$route.query)
				})
		}
	}
}
</script>
<style>
</style>
